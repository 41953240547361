<template>
  <vs-row
    vs-justify="center"
    class="bg-white"
  >
    <vs-col
      type="flex"
      vs-justify="center"
      vs-align="center"
      vs-lg="12"
      vs-xs="12"
    >
      <vs-table
        v-if="boxName !== 'neg-fanpush'"
        id="contact-table"
        ref="table"
        v-model="selected"
        stripe
        multiple
        pagination
        :max-items="nregs"
        search
        :data="data"
        class="text-nowrap"
      >
        <div slot="header">
          <h3>{{ titulo }}</h3>
        </div>
        <template slot="thead">
          <vs-th sort-key="prodDesc">
            Descrição
          </vs-th>
          <vs-th sort-key="value">
            Valor Acumulado
          </vs-th>
          <vs-th sort-key="qt">
            Quantidade
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr
            v-for="(tr, indextr) in data"
            :key="indextr"
            :data="tr"
          >
            <vs-td :data="data[indextr].prodDesc">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].prodDesc }}</span>
              </div>
            </vs-td>

            <vs-td :data="data[indextr].value">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].value | toMoney }}</span>
              </div>
            </vs-td>

            <vs-td :data="data[indextr].qt">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].qt | toInt }}</span>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-table
        v-else
        id="contact-table"
        ref="table"
        v-model="selected"
        stripe
        multiple
        pagination
        :max-items="nregs"
        search
        :data="data"
        class="text-nowrap"
      >
        <div slot="header">
          <h3>Ranking de Pilotos</h3>
        </div>
        <template slot="thead">
          <vs-th sort-key="prodDesc">
            Nome do Piloto
          </vs-th>
          <vs-th sort-key="qt">
            Quantidade de Votos
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr
            v-for="(tr, indextr) in data"
            :key="indextr"
            :data="tr"
          >
            <vs-td :data="data[indextr].prodDesc">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].prodDesc }}</span>
              </div>
            </vs-td>

            <vs-td :data="data[indextr].qt">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].qt | toInt }}</span>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-col>
  </vs-row>
</template>

<script>
var numeral = require('numeral');
export default {
  name: 'RankProd',
  filters: {
    toMoney(value) {
      //numeral.locale('pt-BR');
      var s = numeral(value).format('0,0.00');
      s.replace('.', ';'); // displaying other groupings/separators is possible, look at the docs
      s.replace(',', '.'); // displaying other groupings/separators is possible, look at the docs
      s.replace(';', ','); // displaying other groupings/separators is possible, look at the docs
      return 'R$' + s;
    },
    toInt(value) {
      //numeral.locale('pt-BR');
      var s = numeral(value).format('0,0');
      s.replace(',', '.'); // displaying other groupings/separators is possible, look at the docs                
      return  s;
    }
  },
  components: {

  },
  props: {
    nregs: Number,
    titulo: String,
    data: [],
    boxName: String,
  },

  data: () => ({
    selected: [],
    title: 'Produtos',

  }),
  computed: {

  },
  methods: {
  }
};
</script>
<style>
      .vs-tabs--li {
    display: block;
    position: relative;
}
.con-vs-card {
    margin-bottom: 20px;
    border-radius: 4px;
    min-height: 200px;
}
.chipsMargin {
 margin-bottom: 5px;
}

.chipsMargin:hover {
 background-color: #cfcbcb;
 border-color: #cfcbcb;
}

.chipsRevert {
 background-color: blanchedalmond;
 color: #000;
}
</style>
