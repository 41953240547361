<template>
  <div>
    <vs-row vs-justify="center">
      <vs-col
        vs-lg="12"
        vs-xs="12"
        vs-sm="12"
      >
        <vs-card>
          <FanNegocios
            :chart-data="chart"
            :title="title"
            @get-chart="getChart"
          />
        </vs-card>
      </vs-col>
    </vs-row>

    <vs-row vs-justify="start">
      <vs-col
        v-for="(ls, index) in filteredList"
        :key="index"
        :vs-lg="getColsize(ls.type)"
        vs-xs="12"
        vs-sm="12"
      >
        <BarChart
          v-if="ls.type == 'Lista'"
          :key="ls.code"
          :data="ls.values"
          :title="ls.title"
          :indicator="ls.code"
        />

        <LogoChart
          v-if="ls.type == 'Browse'"
          :key="ls.code"
          :data="ls.values"
          :title="ls.title"
          :indicator="ls.code"
        />

        <SuperFans
          v-if="ls.type == 'SuperFans'"
          :title="ls.title.toUpperCase()"
          :show-desc="false"
          :data="ls.values"
        />

        <vs-card
          v-if="ls.type == 'Donut'"
          style="height: 500px;"
        >
          <DonutChart
            :key="ls.code"
            :title="ls.title"
            :data="ls.values"
          />
        </vs-card>
      </vs-col>
    </vs-row>

    <vs-row
      v-if="!negConfig.hideProd"
      vs-justify="center"
    >
      <vs-col
        v-for="(tr, indextr) in negStats.rankingProdutos"
        :key="indextr"
        vs-lg="12"
        vs-xs="12"
        vs-sm="12"
      >
        <vs-card>
          <Produtos
            :nregs="5"
            :box-name="boxName"
            :titulo="tr.tipoDesc"
            :data="tr.rankingProd"
          />
        </vs-card>
      </vs-col>
    </vs-row>

    <vs-row
      v-if="!negConfig.hideCli"
      vs-justify="center"
    >
      <vs-col
        vs-lg="12"
        vs-xs="12"
        vs-sm="12"
      >
        <vs-card>
          <Clientes
            :box-name="boxName"
            :nregs="5"
            :data="negStats.rankingClients"
          />
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
/* Components */
import Clientes from '../dashboard-components/box-fanbase/TabClients.vue';
import FanNegocios from '../dashboard-components/box-fanbase/FanNegocios.vue';
import Produtos from '../dashboard-components/box-fanbase/TabProd.vue';

// Components - Charts
import BarChart from '../dashboard-components/box-fanbase/BarChart.vue';
import DonutChart from '../dashboard-components/box-fanbase/DonutChart.vue';
import LogoChart from '../dashboard-components/box-fanbase/LogoChart.vue';
import SuperFans from '@/views/dashboards/dashboard-components/box-fanbase/SuperFans.vue';

/* Config */
import { configDomain } from '@/global';

/* Services */
import axios from 'axios';
import Survey from '@/services/survey';

const surveyService = new Survey();

export default {
  name: 'NegociosGeral',
  components: {
    BarChart,
    Clientes,
    DonutChart,
    FanNegocios,
    LogoChart,
    Produtos,
    SuperFans,
  },
  props: {
    boxName: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    /* Box */
    boxStat: {},
    boxIndicators: [],
    /* Chart */
    title: '',
    chart: {},
    /* General */
    negConfig: {},
    negStats: {},
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    filteredList() {
      if (!this.boxIndicators || !this.boxIndicators.length) return [];
      return this.boxIndicators.filter((el) => el.type !== 'Hidden');
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  watch: {
    chart(value) {
      // Since the "getBox" request typically takes longer,
      // make it only when the chart request has been fulfilled
      if (Object.keys(value).length > 0) {
        this.getBox();
      }
    },
  },
  mounted() {
    this.$store.dispatch('set_isLoadingActive', true);

    this.getChart({});
    this.getNegConfig();
    this.getNegStats();
  },
  methods: {
    /* API */
    getBox() {
      this.$store.dispatch('set_isLoadingActive', true);

      surveyService.getBox(
        this.boxName,
        this.ownerId,
        this.token
      )
        .then((resp) => {
          this.boxStat = resp;
          this.boxIndicators = resp.listas;
          this.title = resp.boxTitle;
        })
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    getChart(options) {
      const typeMap = {
        sociotorcedor: 1,
        vendadeingressos: 2,
        ecommerce: 3,
        lojasfisicas: 4,
        ott: 5,
      };

      const businessType = typeMap[this.boxName.toLowerCase()];

      surveyService.getNegChart(
        this.ownerId,
        businessType,
        options,
        this.token
      )
        .then((resp) => (this.chart = resp));
    },
    getNegConfig() {
      const url = `${configDomain}/sys/101/${this.ownerId}/config_neg.json`;

      axios.get(url)
        .then((resp) => {
          const data = JSON.parse(JSON.stringify(resp));
          const negs = data.data.configs;

          this.negConfig = negs.find((el) => el.name === this.boxName);
        })
        .catch((err) => alert(err));
    },
    getNegStats() {
      surveyService.getNegStats(
        this.ownerId,
        this.boxName,
        this.token
      )
        .then((resp) => {
          this.$store.commit('SET_negStats', resp);
          this.negStats = resp;
        });
    },
    /* Helpers */
    getColsize(type) {
      return type === 'Donut' ? 8 : 4;
    },
  },
};
</script>

<style scoped lang="scss">
.stat-list-card--title {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: normal;
}
</style>
