<template>
  <div class="superfans">
    <!-- Header -->
    <div
      slot="header"
      class="superfans-header card-title"
    >
      <!-- Title -->
      <span
        class="m-0"
        style="font-size:0.82rem;"
        :style="{ '-webkit-font-smoothing': 'antialiased' }"
      >
        {{ title.toUpperCase() }}
      </span>
    </div>

    <div class="superfans-content">
      <i
        class="mdi mdi-star-circle display-2 d-block primary"
        style="font-size:4.5rem;"
        :style="{ color: colorSecondary }"
      />

      <span
        class="display-2 d-block"
        style="font-size:4rem;"
      >
        {{ total | formatLocale }}
      </span>

      <span
        v-if="showDesc"
        class="font-weight-bold"
      >100% engajados</span>

      <!-- <vs-button
        class="mt-4"
        :disabled="total === 0"
        icon="people"
        @click="prepareFilter"
      >
        Mostrar registros
      </vs-button> -->
    </div>
  </div>
</template>

<script>
/* Services */
import Filter from '@/services/filter.js';

const filterService = new Filter();

export default {
  name: 'SuperFans',
  props: {
    title: {
      type: String,
      default: '',
    },
    showDesc: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    filterUid: '-',
  }),
  computed: {
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    ownerId() {
      return parseInt(this.$store.state.ownerID);
    },
    total() {
      if (this.data.length > 0) {
        if (this.data[0].value) {
          return this.data[0].value;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    filterAnswers() {
      // Select all superfans
      return [
        {
          filterOptions: null,
          id: -1,
          options: [
            {
              option: 'SIM',
              config: '|'
            },
          ],
          questionName: 'Superfans',
        },
      ];
    },
  },
  methods: {
    /* API */
    prepareFilter() {
      filterService
        .prepareFilter(
          this.ownerId,
          this.filterUid,
          this.filterAnswers,
        )
        .then(
          (resp) => {
            this.$store.commit('SET_filterUid', resp.filterUid);
            this.$store.commit('SET_selectedContacts', resp.nRegisters);

            this.$store.dispatch('set_isLoadingActive', false);

            this.goToContacts();
          },
          (err) => {
            this.$store.dispatch('set_isLoadingActive', false);

            alert(err);
          }
        );
    },
    /* Router */
    goToContacts() {
      this.$router.push({
        name: 'Lista de Registros',
        params: {
          fromFilter: true,
          filterSaved: false,
        },
      });
    },
  }
};
</script>

<style scoped lang="scss">
.superfans {
  background-color: white;
  border: 0;
  border-radius: .3rem;
  display: flex;
  flex-flow: column nowrap;
  height: 500px;
  margin-bottom: 1rem;

  .superfans-header {
    align-items: center;
    background-color: rgba(0, 0, 0, .64);
    border: 0;
    border-radius: .3rem .3rem 0 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    justify-content: space-between;
    max-height: 55.05px;
    padding: 1rem;

    span {
      color: #FAFAFA;
    }
  }

  .superfans-content {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    overflow: auto;
    padding: 0 1rem 1rem;
    height: 100%;
  }
}
</style>
