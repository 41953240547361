<template>
  <div>
    <!-- Header -->
    <vs-row>
      <vs-col vs-lg="12">
        <div class="d-flex align-items-center justify-content-between mb-4">
          <!-- Title -->
          <h3 class="card-title">
            {{ title }}
          </h3>

          <!-- Controls -->
          <div class="d-flex align-items-center">
            <vs-select
              v-model="selectedView"
              :disabled="views.length < 1"
              class="w-250px mr-2"
            >
              <vs-select-item
                v-for="view in views"
                :key="view.name"
                :value="view"
                :text="view.name"
              />
            </vs-select>

            <vs-select
              v-model="overviewZoom"
              class="w-250px mr-2"
            >
              <vs-select-item
                v-for="item in zoomOptions"
                :key="item.value"
                :value="item.value"
                :text="item.text"
              />
            </vs-select>

            <vs-button
              class="btn btn-secondary text-light"
              icon="sync"
              @click="() => getChart({ zoom: overviewZoom })"
            >
              Atualizar dados
            </vs-button>
          </div>
        </div>
      </vs-col>
    </vs-row>

    <hr class="custom-hr">

    <!-- Chart -->
    <vs-row vs-justify="center">
      <vs-col
        vs-lg="12"
        vs-xs="12"
        vs-sm="12"
      >
        <!-- Key attribute is responsible for updating/re-rendering the chart -->
        <!-- Please do not remove -->
        <vue-apex-charts
          :key="series"
          height="250"
          type="area"
          :options="chartOptions"
          :series="series"
        />
      </vs-col>
    </vs-row>

    <!-- Footer -->
    <hr class="custom-hr">
  </div>
</template>

<script>
/* Components */
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'FanNegocios',
  components: { VueApexCharts },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    /* Chart */
    series: [],
    chartOptions: {
      dataLabels: {
        enabled: false,
      },
      chart: {
        toolbar: {
          show: true,
        },
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      colors: [],
      xaxis: {
        type: 'category',
        labels: {
          style: {
            cssClass: 'text-muted fill-color',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            cssClass: 'text-muted fill-color',
          },
        },
      },
      markers: { size: 3 },
      tooltip: {
        x: { format: 'dd/MM/yy HH:mm' },
        y: { formatter: null },
        theme: 'dark',
      },
      legend: { show: false },
      grid: {
        borderColor: 'rgba(0,0,0,0.1)',
        show: true,
      },
    },
    /* Views */
    views: [],
    selectedView: {},
    /* Zooms */
    overviewZoom: 'y',
    zoomOptions: [
      { text: 'Últimos 24 meses', value: 'm_24' },
      { text: 'Últimos 12 meses', value: 'y' },
      { text: 'Últimos 6 meses', value: 'm_6' },
      { text: 'Últimos 3 meses', value: 'm_3' },
      { text: 'Últimos 2 meses', value: 'm_2' },
      { text: 'Último mês', value: 'm' },
      { text: 'Últimas 3 semanas', value: 'w_3' },
      { text: 'Últimas 2 semanas', value: 'w_2' },
      { text: 'Última semana', value: 'w' },
    ],
  }),
  computed: {
    primaryColor() {
      return this.$store.state.configBox.cor1;
    },
    secondaryColor() {
      return this.$store.state.configBox.cor2;
    },
  },
  watch: {
    chartData() {
      // When chart gets updated, set series
      this.setChartViews();
    },
    overviewZoom(value) {
      this.getChart({ zoom: value });
    },
    selectedView(value) {
      // When a view gets selected, set series
      this.setChartSeries(value);
    },
  },
  created() {
    this.setChartColors();
  },
  mounted() {
    this.setChartViews();
  },
  methods: {
    /* API */
    getChart(options) {
      this.$emit('get-chart', options);
    },
    /* Helpers */
    getMonth(m) {
      const monthNames = {
        1: 'Jan', 2: 'Fev', 3: 'Mar', 4: 'Abr',
        5: 'Mai', 6: 'Jun', 7: 'Jul', 8: 'Ago',
        9: 'Set', 10: 'Out', 11: 'Nov', 12: 'Dez',
      };

      return monthNames[m] || '';
    },
    abbreviateMonth(m) {
      const abbreviations = {
        'January': 'Jan', 'February': 'Fev', 'March': 'Mar', 'April': 'Abr',
        'May': 'Mai', 'June': 'Jun', 'July': 'Jul', 'August': 'Ago',
        'September': 'Set', 'October': 'Out', 'November': 'Nov', 'December': 'Dez',
      };

      return abbreviations[m];
    },
    setChartColors() {
      this.chartOptions.colors.unshift(this.secondaryColor);
      this.chartOptions.colors.unshift(this.primaryColor);
    },
    setChartSeries(selectedView) {
      // Format x axis labels a.k.a "categories" and reduce chart granularity
      for (let series of selectedView.series) {
        if (
          this.overviewZoom.includes('y')
            || this.overviewZoom.includes('m_24')
        ) {
          // Abbreviate month names
          series.data = series.data.map(({ x, y }) => {
            const [ month, year ] = x.split('/');

            return ({
              x: `${this.abbreviateMonth(month) || month}/${year}`,
              y,
            });
          });
        } else if (
          this.overviewZoom.includes('m')
            || this.overviewZoom.includes('w')
        ) {
          series.data = series.data.map(({ x, y }, i) => {
            const [ year, month, day ] = x.split('-');
            const monthName = this.getMonth(parseInt(month, 0));

            // Add spline condition
            let addToSplines = true;

            // Formats
            let concatMonth = false;
            let concatYear = false;

            // Only include days 1, 7, 14, 21
            if (['m_12', 'm_6', 'm_3', 'm_2'].includes(this.overviewZoom)) {
              addToSplines = day === '01' || day === '07' || day === '14' || day === '21';
              concatYear = false;
              concatMonth = true;
            }

            // Concat first of month
            if (this.overviewZoom.includes('w') || this.overviewZoom === 'm') {
              if (i === 0 || day === '01') {
                concatYear = false;
                concatMonth = true;
              }
            }

            if (addToSplines) {
              if (concatYear) {
                // Return concatenated month and year
                return ({ x: `${monthName}/${year}`, y });
              } else if (concatMonth) {
                // Return concatenated month and day
                return ({ x: `${monthName}/${day}`, y });
              } else {
                // Return day
                return ({ x: day, y, });
              }
            } else {
              // Skip value
              return;
            }
          });

          // Remove "undefined" values from array
          series.data = series.data.filter((item) => item);
        }
      }

      this.series = selectedView.series;
    },
    setChartViews() {
      if (this.chartData.views && this.chartData.views.length > 0) {
        this.views = this.chartData.views;

        // Set initial selected view
        this.selectedView = this.chartData.views[0];
      }
    },
  },
};
</script>

<style>
.date-range-btn {
  background-color: transparent;
  border: 0px;
  font-size: 1.3rem;
  font-weight: 700;
  color: rgb(var(--vs-primary));
}

.date-range-btn:hover {
  color: rgb(var(--vs-secondary));
}
</style>
