<template>
  <vs-row
    vs-justify="center"
    class="bg-white"
  >
    <vs-col
      type="flex"
      vs-justify="center"
      vs-align="center"
      vs-lg="12"
      vs-xs="12"
    >
      <vs-table
        v-if="boxName !== 'neg-fanpush'"
        id="contact-table"
        ref="table"
        v-model="selected"
        stripe
        pagination
        :max-items="nregs"
        search
        :data="data"
        class="text-nowrap"
      >
        <div slot="header">
          <div slot="header">
            <h3>Ranking de Clientes</h3>
          </div>
        </div>
        <template slot="thead">
          <vs-th sort-key="identity">
            ID
          </vs-th>
          <vs-th sort-key="name1">
            Nome
          </vs-th>
          <vs-th sort-key="minDate">
            1ª Compra
          </vs-th>
          <vs-th sort-key="maxDate">
            Última Compra
          </vs-th>
          <vs-th sort-key="total">
            Valor Total
          </vs-th>
          <vs-th sort-key="qt">
            Qtd de Compras
          </vs-th>
          <vs-th sort-key="media">
            Valor Médio
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr
            v-for="(tr, indextr) in data"
            :key="indextr"
            :data="tr"
          >
            <vs-td :data="data[indextr].identity">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].identity }}</span>
              </div>
            </vs-td>

            <vs-td :data="data[indextr].name1">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].name1 }}</span>
              </div>
            </vs-td>

            <vs-td :data="data[indextr].minDate">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].minDate | toDate }}</span>
              </div>
            </vs-td>
            <vs-td :data="data[indextr].maxDate">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].maxDate | toDate }}</span>
              </div>
            </vs-td>
            <vs-td :data="data[indextr].total">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].total | toMoney }}</span>
              </div>
            </vs-td>
            <vs-td :data="data[indextr].qt">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].qt }}</span>
              </div>
            </vs-td>
            <vs-td :data="data[indextr].media">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].media | toMoney }}</span>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-table
        v-else
        id="contact-table"
        ref="table"
        v-model="selected"
        stripe
        pagination
        :max-items="nregs"
        search
        :data="dataSortByQt"
        class="text-nowrap"
      >
        <div slot="header">
          <div slot="header">
            <h3>Ranking de Eleitores</h3>
          </div>
        </div>
        <template slot="thead">
          <vs-th sort-key="name1">
            Nome
          </vs-th>
          <vs-th sort-key="minDate">
            1ª votação
          </vs-th>
          <vs-th sort-key="maxDate">
            Última votação
          </vs-th>
          <vs-th sort-key="qt">
            Qtd de Votações
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr
            v-for="(tr, indextr) in data"
            :key="indextr"
            :data="tr"
          >
            <vs-td :data="data[indextr].name1">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].name1 }}</span>
              </div>
            </vs-td>

            <vs-td :data="data[indextr].minDate">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].minDate | toDate }}</span>
              </div>
            </vs-td>
            <vs-td :data="data[indextr].maxDate">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].maxDate | toDate }}</span>
              </div>
            </vs-td>
            <vs-td :data="data[indextr].qt">
              <div class="d-flex align-items-center">
                <span class="text-muted">{{ data[indextr].qt }}</span>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-col>
  </vs-row>
</template>

<script>
var numeral = require('numeral');

export default {
  name: 'RankClients',
  filters: {
    toMoney(value) {
      //numeral.locale('pt-BR');
      var s = numeral(value).format('0,0.00');
      s.replace('.', ';'); // displaying other groupings/separators is possible, look at the docs
      s.replace(',', '.'); // displaying other groupings/separators is possible, look at the docs
      s.replace(';', ','); // displaying other groupings/separators is possible, look at the docs
      return 'R$' + s;
    },
    toDate(value) {
      let d = new Date(value)
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return d.toLocaleString('pt-BR', options);
    }
  },
  props: {
    nregs: Number,
    data: Array,
    boxName: String
  },

  data: () => ({
    selected: [],
    title: 'Ranking de Clientes',
  }),
  computed: {
    dataSortByQt() {
      let s = this.data.slice()
      s.sort(function (a, b) {
        return a.qt - b.qt;
      });
      return s.reverse()
    }

  },
};
</script>
<style>
.vs-tabs--li {
  display: block;
  position: relative;
}

.con-vs-card {
  margin-bottom: 20px;
  border-radius: 4px;
  min-height: 200px;
}

.chipsMargin {
  margin-bottom: 5px;
}

.chipsMargin:hover {
  background-color: #cfcbcb;
  border-color: #cfcbcb;
}

.chipsRevert {
  background-color: blanchedalmond;
  color: #000;
}
</style>
